import { Instance, SnapshotIn, types, flow, onSnapshot, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';
import { USERS_IN_GROUP_ID, ALL_USERS_ID } from '@shared/constants';
import { AnalyticsGridStore } from './AnalyticsGrid.store';
import { IGroupsDataStoreSnaphotOut, GroupUpdate$ } from 'Groups/store';
import { IPageQueryStoreSnapshotIn } from './PageQuery.store';

export const AnalyticsGridUIStoreInferred = types
    .model('AnalyticsGridUIStoreInferred', {
        _analytics: types.optional(AnalyticsGridStore, {}),
        isActivePage: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
    }))
    .views(self => ({
        get users() { return self._analytics.analyticsList; },
        get pagination() { return self._analytics.paginationStatus; },
        get requestParams() { return self._analytics.requestParams; },
        get statistics() { return self._analytics.statistics; },
        get isFilterOrSearchApplied() { return self._analytics.requestParams.areSearchParamsSet; },
    }))
    .views(self => ({
        get isTableVisible() { return self.users.length || self.status.isLoading; },
        get noFilteringResults() { return !self.users.length && !self.status.isLoading && self.isFilterOrSearchApplied },
        get noItemsInGroup() { return !self.users.length && !self.status.isLoading && !self.isFilterOrSearchApplied },
    }))
    .actions(self => {
        const load = flow(function* () {
            self.status.setLoading();
            yield self._analytics.loadAnalytics();
            self.status.setDone();
        });

        const loadStatistics = flow(function* () {
            yield self._analytics.loadStatistics();
        });

        const setParams = (params: IPageQueryStoreSnapshotIn) => {
            self.requestParams.setParams(params);
            self.requestParams.saveParamsToStorage();
        }

        const resetParams = () => self.requestParams.resetParams();

        const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

        const cleanUp = () => {
            resetParams();
            self._analytics.cleanData();
        }

        return {
            load,
            setParams,
            resetParams,
            loadStatistics,
            togglePageActivity,
            cleanUp,
        }
    })
    .actions(self => {
        const { groups } = getEnv<IStoresEnv>(self);

        return {
            afterCreate: () => {
                GroupUpdate$.subscribe(() => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }
                });

                onSnapshot(self.requestParams, () => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }

                    self.requestParams.saveParamsToStorage();
                });

                onSnapshot(groups, (snap: IGroupsDataStoreSnaphotOut) => {
                    if (snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: USERS_IN_GROUP_ID,
                            inGroupId: snap.selectedGroup.toString(),
                        });
                    }

                    // Show 0 page of 'All users' after group removing
                    if (self.requestParams.inGroup === USERS_IN_GROUP_ID && !snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: ALL_USERS_ID,
                            inGroupId: undefined,
                        });
                    }
                })
            },
        }
    })

type AnalyticsGridUIStoreFactoryType = typeof AnalyticsGridUIStoreInferred;
interface IAnalyticsGridUIStoreFactory extends AnalyticsGridUIStoreFactoryType { }
export const AnalyticsGridUIStore: IAnalyticsGridUIStoreFactory = AnalyticsGridUIStoreInferred;
export interface IAnalyticsGridUIStore extends Instance<IAnalyticsGridUIStoreFactory> { }
export interface IAnalyticsGridUIStoreSnapshotIn extends SnapshotIn<IAnalyticsGridUIStore> { }
